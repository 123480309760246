import { ReCaptchaInstance } from "recaptcha-v3";

export const captchaFetch = (
  token: ReCaptchaInstance | undefined,
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> =>
  token
    ? token.execute("request").then((result) =>
        fetch(input, {
          ...init,
          headers: {
            ...init?.headers,
            "x-recaptcha-token": result,
          },
        })
      )
    : fetch(input, {
        ...init,
        headers: {
          ...init?.headers,
          "x-recaptcha-token": "fail-token",
        },
      });
