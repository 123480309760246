import { ReCaptchaInstance } from "recaptcha-v3";
import { ContactMe } from "../../models";
import { CONTACT_ME } from "./addresses";
import { captchaFetch } from "./captcha-fetch";

export const contactMe = (
  data: ContactMe,
  token: ReCaptchaInstance
): Promise<boolean> =>
  captchaFetch(token, CONTACT_ME, {
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    method: "post",
  }).then((result) => result.status === 200);
