import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface InstallationDialogComponentProps {
  title: React.ReactNode;
  text: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export const InstallationDialogComponent: React.FC<InstallationDialogComponentProps> = ({
  open,
  onClose,
  title,
  text,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="dialog-help"
      open={open}
      fullScreen={fullScreen}
    >
      <DialogTitle id="dialog-help">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="dialog-help-description"
          style={{ textAlign: "center" }}
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
