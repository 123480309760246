import React, { lazy, Suspense } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  LayoutStepper,
  MainLayout,
  CaptchaContext,
  LoadingBackdrop,
} from "./components";
import { useAppHook } from "./app.hook";
import { availableSteps } from "./available-steps.variable";
import { AutocompleteSuggestion, Package, Promo } from "./models";
import { handleDarkChange } from "./utils/dark-media-query.util";

const CoverageCheckComponent = lazy(() => import("./steps/coverage-check"));
const FileUploadComponet = lazy(() => import("./steps/file-upload"));
const PackageSelectorComponent = lazy(() => import("./steps/package-selector"));
const SubscriberDataComponent = lazy(() => import("./steps/subscriber-data"));
const SummaryComponent = lazy(() => import("./steps/summary"));

export const App: React.FC = () => {
  const {
    theme,
    currentStep,
    selectedPackage,
    setSelectedPackage,
    prevNext,
    nextActive,
    prevActive,
    addressSelected,
    promos,
    setAddressSelected,
    setPromos,
    setDarkMode,
    setContractData,
    requestId,
    captchaToken,
  } = useAppHook(availableSteps, window.location.hash.replace("#", ""));

  handleDarkChange(setDarkMode);

  const handleAddressSelected = (
    address: AutocompleteSuggestion,
    promos?: Promo[]
  ) => {
    setAddressSelected(address);
    setPromos(promos || []);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainLayout
        stepper={
          <LayoutStepper
            activeStep={currentStep}
            steps={availableSteps}
            nextActive={nextActive}
            prevActive={prevActive}
            onPrevNext={prevNext}
          />
        }
      >
        <CaptchaContext.Provider value={captchaToken}>
          {currentStep === 0 && (
            <Suspense fallback={<LoadingBackdrop />}>
              <CoverageCheckComponent
                onAddressSelected={handleAddressSelected}
              />
            </Suspense>
          )}
          {currentStep === 1 && (
            <Suspense fallback={<LoadingBackdrop />}>
              <PackageSelectorComponent
                token={captchaToken}
                address={addressSelected?.address || ""}
                onSelectedPackage={setSelectedPackage}
              />
            </Suspense>
          )}
          {currentStep === 2 && !!selectedPackage && (
            <Suspense fallback={<LoadingBackdrop />}>
              <SummaryComponent
                selectedPackage={selectedPackage as Package}
                promos={promos}
              />
            </Suspense>
          )}
          {currentStep === 3 && (
            <Suspense fallback={<LoadingBackdrop />}>
              <SubscriberDataComponent
                street={addressSelected ? addressSelected.street : ""}
                number={addressSelected ? addressSelected.number : "S/N"}
                selectedPackage={selectedPackage as Package}
                onSubscriberData={setContractData}
              />
            </Suspense>
          )}
          {currentStep === 4 && (
            <Suspense fallback={<LoadingBackdrop />}>
              <FileUploadComponet requestId={requestId} />
            </Suspense>
          )}
        </CaptchaContext.Provider>
      </MainLayout>
    </ThemeProvider>
  );
};
