import React from "react";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";

interface FileInputButtonComponentProps {
  label: string;
  success: boolean;
  onSelectFile: (selectedFile: File) => void;
}

export const FileInputButtonComponent: React.FC<FileInputButtonComponentProps> =
  ({ label, success, onSelectFile }) => {
    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(
      null
    );
    const handleFileUpload = () => {
      inputRef?.click();
    };

    const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.files &&
        event.target.files[0] &&
        onSelectFile(event.target.files[0]);
    };

    return (
      <>
        <Button
          color={success ? "default" : "primary"}
          size="large"
          variant="contained"
          fullWidth
          onClick={handleFileUpload}
        >
          {label}
          {success ? <CheckIcon fontSize="small" /> : ""}
        </Button>
        <input
          type="file"
          accept="application/pdf,image/*"
          ref={setInputRef}
          style={{ display: "none" }}
          onChange={onFileSelected}
          capture
        />
      </>
    );
  };
