import { makeStyles } from "@material-ui/core/styles";

export const useMainLayoutStyles = makeStyles((theme) => ({
  mainGrid: {
    height: "100%",
  },
  mainTitle: {
    textAlign: "center",
  },
  cardRoot: {
    width: "100%",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(7),
  },
  content: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}));
