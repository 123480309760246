export interface Package {
  id: number;
  name: string;
  products: Products[];
  monthlyPrice: number;
  monthlyPriceAfterOffer: number;
  offerMonths: number;
  permanencePeriod: number;
  installationPrice: number;
  hasPromos: boolean;
}

export type Products = "Internet" | "Television" | "Landline" | "Mobile";

export const productLabel = new Map<Products, string>([
  ["Internet", "Internet"],
  ["Television", "Televisión"],
  ["Landline", "Teléfono Fijo"],
  ["Mobile", "Teléfono Móvil"],
]);

export const productDetail = new Map<Products, string>([
  ["Internet", "Internet por fibra de alta velocidad"],
  ["Television", "Televisión premium con más de 100 canales"],
  ["Landline", "Teléfono fijo con tarifa plana de llamadas"],
  ["Mobile", "Línea móvil con plan de datos"],
]);

export const availableProducts: Products[] = [
  "Internet",
  "Television",
  "Mobile",
  "Landline",
];
