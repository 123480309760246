import { ReCaptchaInstance } from "recaptcha-v3";
import { ContractData } from "../../models";
import { SUBSCRIBER } from "./addresses";
import { captchaFetch } from "./captcha-fetch";

export const registerSubscriber = (
  contractData: ContractData,
  origin: string,
  token: ReCaptchaInstance
): Promise<string> =>
  captchaFetch(token, SUBSCRIBER, {
    body: JSON.stringify({ contractData, origin }),
    headers: { "Content-Type": "application/json" },
    method: "post",
  })
    .then((result) => result.json())
    .then((data) => data.uuid as string);
