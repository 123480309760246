export const handleDarkChange = (handlerFunction: (dark: boolean) => void) => {
  const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  try {
    // Chrome & Firefox
    darkMediaQuery.addEventListener("change", (e) => {
      handlerFunction(darkMediaQuery.matches);
    });
  } catch (e1) {
    try {
      // Safari
      darkMediaQuery.addListener((e) => {
        handlerFunction(darkMediaQuery.matches);
      });
    } catch (e2) {
      console.error(e2);
    }
  }
};
