import { ReCaptchaInstance } from "recaptcha-v3";
import { CoverageCheck, CoverageResult } from "../../models";
import { COVERAGE_CHECK } from "./addresses";
import { captchaFetch } from "./captcha-fetch";

export const checkCoverage = (
  { number, placeId, street }: CoverageCheck,
  token: ReCaptchaInstance
): Promise<CoverageResult> =>
  captchaFetch(
    token,
    `${COVERAGE_CHECK}street=${street}&number=${number}&placeId=${placeId}`
  )
    .then((result) => result.json() as Promise<CoverageResult>)
    .catch(() => {
      alert(
        "Error solicitando los datos de cobertura. Por favor inténtelo de nuevo"
      );
      return { coverage: false, promos: [] };
    });
