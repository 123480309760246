import isEmail from "sane-email-validation";
import IBAN from "iban";

export interface ContractData {
  packageId: number;
  street: string;
  streetNumber: string;
  addressRest: string;
  name: string;
  surname: string;
  idNumber: string;
  mobile: string;
  iban: string;
  email: string;
  secondTelephone: string;
  numberPortabilities: string;
  referer: string;
  promos: number[];
}

export const initialContractData: ContractData = {
  packageId: 0,
  street: "",
  streetNumber: "",
  addressRest: "",
  name: "",
  surname: "",
  idNumber: "",
  mobile: "",
  iban: "",
  email: "",
  secondTelephone: "",
  numberPortabilities: "",
  referer: new URLSearchParams(window.location.search).get("referer") || "",
  promos: [],
};

export const validateContractDataFields = (isSeasonal:boolean) => ({
  packageId: (value: string) => "",
  street: (value: string) => "",
  streetNumber: (value: string) => "",
  addressRest: (value: string) => "",
  name: (value: string) =>
    value.length > 1 ? "" : "Introduzca más de 2 caracteres",
  surname: (value: string) =>
    value.length > 1 ? "" : "Introduzca más de 2 caracteres",
  idNumber: (value: string) =>
    value.length > 5
      ? value.length < 11
        ? ""
        : "Introduzca máximo 10 caracteres"
      : "Introduzca más de 5 caracteres",
  mobile: (value: string) =>
    value.length >= 9 ? "" : "Introduzca 9 o más caracteres",
  iban: (value: string) =>
    (isSeasonal || IBAN.isValid(value)) ? "" : "Introduzca un IBAN válido",
  email: (value: string) =>
    isEmail(value) ? "" : "Introduzca un email válido",
  secondTelephone: (value: string) => "",
  numberPortabilities: (value: string) => "",
  referer: (value: string) => "",
  promos: (value: string) => "",
});

export const validateContractData = (isSeasonal:boolean) => (contractData: ContractData): boolean =>
  (Object.keys(contractData) as (
    | "packageId"
    | "street"
    | "streetNumber"
    | "addressRest"
    | "name"
    | "surname"
    | "idNumber"
    | "mobile"
    | "iban"
    | "email"
    | "secondTelephone"
    | "numberPortabilities"
  )[]).every(
    (el) => validateContractDataFields(isSeasonal)[el](contractData[el] as string) === ""
  );
