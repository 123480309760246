import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

interface TvtSwitchComponentProps {
  label: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  IconComponent: React.ReactNode;
}

export const TvtSwitchComponent: React.FC<TvtSwitchComponentProps> = ({
  checked,
  IconComponent,
  label,
  onChange,
  disabled,
}) => (
  <Tooltip title={label} aria-label={label}>
    <FormControlLabel
      disabled={disabled}
      labelPlacement={
        useMediaQuery(useTheme().breakpoints.up("md")) ? "start" : "top"
      }
      control={
        <Switch
          color="secondary"
          disabled={disabled}
          title={label}
          value={checked}
          onChange={onChange}
        />
      }
      label={
        useMediaQuery(useTheme().breakpoints.up("md")) ? label : IconComponent
      }
    />
  </Tooltip>
);
