import React from "react";
import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

export interface CustomTextFieldProps extends StandardTextFieldProps {}

export const CustomTextField: React.FC<CustomTextFieldProps> = (props) => (
  <Grid item xs={12} sm={12} md={6}>
    <TextField {...props} fullWidth />
  </Grid>
);
