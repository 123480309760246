import React from "react";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

export const RequestLoadingComponent: React.FC = () => (
  <Grid container alignContent="center" direction="column">
    <LinearProgress />
    <Typography variant="h5" component="div">
      Enviando información...
    </Typography>
  </Grid>
);
