import { ReCaptchaInstance } from "recaptcha-v3";
import { UPLOADED } from "./addresses";
import { captchaFetch } from "./captcha-fetch";

export const getUploadedFiles = (
  id: string,
  token: ReCaptchaInstance
): Promise<string[]> =>
  captchaFetch(token, `${UPLOADED}/${id}`)
    .then((result) => result.json())
    .then((data) => data as string[]);
