import React from "react";
import ReactDOM from "react-dom";
import GoogleFontLoader from "react-google-font-loader";
import "./index.css";
import { App } from "./app.component";

ReactDOM.render(
  // <React.StrictMode>
  <>
    <GoogleFontLoader
      fonts={[
        {
          font: "Roboto",
          weights: [300, 400, 500, 700],
        },
      ]}
      display="swap"
    />
    <App />
  </>,
  /*</React.StrictMode>*/ document.getElementById("root")
);
