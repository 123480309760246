import { useState, useEffect, useCallback } from "react";
import { ReCaptchaInstance } from "recaptcha-v3";
import {
  AutocompleteSuggestion,
  ContractData,
  initialContractData,
  Package,
  Promo,
  validateContractData,
} from "./models";
import { APIService, RecaptchaService } from "./services";
import { darkTheme, lightTheme } from "./theme";

export const useAppHook = (
  availableSteps: string[],
  urlHash: string | undefined
) => {
  const [darkMode, setDarkMode] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
  const [theme, setTheme] = useState(lightTheme);
  const [currentStep, setCurrentStep] = useState(urlHash ? 4 : 0);
  const [addressSelected, setAddressSelected] =
    useState<AutocompleteSuggestion | null>(null);
  const [promos, setPromos] = useState<Promo[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [contractData, setContractData] = useState<ContractData>({
    ...initialContractData,
  });
  const [prevActive, setPrevActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);
  const [requestId, setRequestId] = useState(urlHash || "");
  const [captchaToken, setCaptchaToken] =
    useState<ReCaptchaInstance | undefined>(undefined);

  const prevNext = (direction: 1 | -1) => () => {
    currentStep === 1 && direction === -1 && setAddressSelected(null);
    setCurrentStep(
      currentStep + direction < 0
        ? 0
        : currentStep + direction >= availableSteps.length
        ? availableSteps.length
        : currentStep + direction
    );
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const formSendError = () => {
    setCurrentStep(3);
    alert("Error al mandar el formulario, por favor inténtelo de nuevo");
  };

  useEffect(() => {
    setRequestId(urlHash || "");
  }, [urlHash]);

  useEffect(() => {
    requestId !== "" && setCurrentStep(4);
  }, [requestId]);

  useEffect(() => {
    if (document.getElementById("bgoverlay"))
      document
        .getElementById("bgoverlay")
        ?.setAttribute("class", darkMode ? "bgDark" : "bgLight");
    setTheme(darkMode ? darkTheme : lightTheme);
  }, [darkMode]);

  useEffect(() => {
    !urlHash && setCurrentStep(addressSelected ? 1 : 0);
  }, [addressSelected, urlHash]);

  useEffect(() => {
    RecaptchaService.recaptchaInit().then(setCaptchaToken);
  }, []);

  const handleRegisterSubscriber = useCallback(() => {
    const registerSubscriber = (captchaToken: ReCaptchaInstance) =>
      APIService.registerSubscriber(
        { ...contractData, promos: promos.map((el) => el.id) },
        window.location.href,
        captchaToken
      )
        .then((result) => {
          if (result) {
            window.location.hash = `#${result}`;
            setRequestId(result);
          } else {
            formSendError();
          }
        })
        .catch((error) => {
          formSendError();
        });

    requestId === "" &&
      captchaToken !== undefined &&
      registerSubscriber(captchaToken);
  }, [requestId, captchaToken, contractData, promos]);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setPrevActive(false);
        setNextActive(false);
        break;
      case 1:
        setPrevActive(true);
        setNextActive(!!selectedPackage);
        break;
      case 2:
        setPrevActive(true);
        setNextActive(true);
        break;
      case 3:
        setPrevActive(true);
        setNextActive(validateContractData(selectedPackage?.permanencePeriod===0)(contractData));
        break;
      case 4:
        handleRegisterSubscriber();
        setPrevActive(false);
        setNextActive(false);
        break;
    }
  }, [currentStep, contractData, selectedPackage, handleRegisterSubscriber]);

  return {
    darkMode,
    theme,
    promos,
    addressSelected,
    selectedPackage,
    currentStep,
    prevActive,
    nextActive,
    contractData,
    requestId,
    captchaToken,
    toggleDarkMode,
    setDarkMode,
    prevNext,
    setAddressSelected,
    setSelectedPackage,
    setContractData,
    setPromos,
  };
};
