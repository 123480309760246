import { createMuiTheme } from "@material-ui/core/styles";

// https://paletton.com/#uid=23s0u0k++HxuZZKRX+V+Wte+amT

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#5CBBFF",
      dark: "#33AAFF",
      light: "#85CCFF",
    },
    secondary: {
      main: "#FFB85C",
      dark: "#FFA025",
      light: "#FFC67D",
    },
    success: {
      main: "#00F500",
    },
    error: {
      main: "#FFADAD",
    },
  },
});
