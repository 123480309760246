import { ReCaptchaInstance } from "recaptcha-v3";
import { SUBSCRIBER } from "./addresses";
import { captchaFetch } from "./captcha-fetch";

export const fileUpload = (
  uuid: string,
  type: string,
  file: File,
  token: ReCaptchaInstance | undefined
): Promise<boolean> => {
  const formData = new FormData();
  formData.append("file", file);
  return captchaFetch(token, `${SUBSCRIBER}/${uuid}/${type}`, {
    method: "post",
    body: formData,
  }).then((result) => result.status === 200);
};
