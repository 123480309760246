import { createMuiTheme } from "@material-ui/core/styles";

// http://paletton.com/#uid=23w0u0k++jnZLuN+XoM+ZdG+X69

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#003b63",
      dark: "#00121F",
      light: "#00121F",
    },
    secondary: {
      main: "#9A5700",
      dark: "#311C00",
      light: "#F58B00",
    },
  },
});
