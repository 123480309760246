import validateMail from "sane-email-validation";

export interface ContactMe {
  name: string;
  address: string;
  telephone: string;
  email: string;
}

export const validateContactMeData = ({
  name,
  address,
  email,
  telephone,
}: ContactMe): boolean =>
  !!name &&
  name.length > 3 &&
  !!address &&
  address.length > 4 &&
  !!email &&
  validateMail(email) &&
  !!telephone &&
  telephone.length > 6;
