import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

const useCustomFormControlStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(),
    border: "1px solid",
    borderColor: theme.palette.divider,
    padding: theme.spacing(2),
  },
}));

interface CustomFormControlComponentProps {
  label: string;
}

export const CustomFormControlComponent: React.FC<CustomFormControlComponentProps> = ({
  children,
  label,
}) => {
  const classes = useCustomFormControlStyles();
  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel component="legend">{label}</FormLabel>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </FormControl>
  );
};
