import React, { lazy, Suspense } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import { LoadingBackdrop } from "../loading-backdrop";
import { useMainLayoutStyles } from "./main-layout.styles";
const LogoComponent = lazy(() => import("../logo"));
interface MainLayoutProps {
  stepper: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({
  stepper,
  children,
}) => {
  const classes = useMainLayoutStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.mainGrid}
    >
      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        xl={6}
        lg={8}
        md={12}
        sm={12}
        xs={12}
      >
        <Card elevation={2} className={classes.cardRoot}>
          <CardContent className={classes.mainTitle}>
            <Suspense fallback={<LoadingBackdrop />}>
              <LogoComponent />
            </Suspense>
            <Divider />
            <Typography variant="h4" component="h1">
              Formulario de Contratación
            </Typography>
          </CardContent>
          <CardContent className={classes.content}>{children}</CardContent>
          <CardActions>{stepper}</CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
