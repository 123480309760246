import { ReCaptchaInstance } from "recaptcha-v3";
import { SUBSCRIBER } from "./addresses";
import { captchaFetch } from "./captcha-fetch";

export const getPortabilities = (
  id: string,
  token: ReCaptchaInstance
): Promise<string[]> =>
  captchaFetch(token, `${SUBSCRIBER}/${id}`)
    .then((result) => result.json())
    .then((data) => data as string[]);
