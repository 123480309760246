import React, { lazy, Suspense } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { LoadingBackdrop } from "../loading-backdrop";

const MobileStepperLayout = lazy(() => import("./mobile-stepper.component"));
const DesktopStepperLayout = lazy(() => import("./desktop-stepper.component"));

export interface LayoutStepperProps {
  steps: string[];
  activeStep: number;
  nextActive: boolean;
  prevActive: boolean;
  onPrevNext: (direction: -1 | 1) => () => void;
}

export const LayoutStepper: React.FC<LayoutStepperProps> = (props) =>
  useMediaQuery(useTheme().breakpoints.up("md")) ? (
    <Suspense fallback={<LoadingBackdrop />}>
      <DesktopStepperLayout {...props} />
    </Suspense>
  ) : (
    <Suspense fallback={<LoadingBackdrop />}>
      <MobileStepperLayout {...props} />
    </Suspense>
  );
